$(function () {
    var offerSwiper = false;
    var networkSwiper = false;

    $(window).on('DOMContentLoadedReady windowResizeBreakpointChanged', function () {
        if (currentWidth <= screenXsMaxWidth) {
            createOfferSwiper();
        } else if (typeof offerSwiper !== 'boolean') {
            $('.home-page .offer .swiper-container').unbind('mouseenter mouseleave');
            offerSwiper.destroy(true, true);
            offerSwiper = false;
        }
    });


    function createOfferSwiper() {
        offerSwiper = new Swiper('.home-page .offer .swiper-container', {
            slidesPerView: 1,
            loop: true,
            preventClicks: false,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: '.home-page .offer .swiper-button-next',
                prevEl: '.home-page .offer .swiper-button-prev',
            },
            pagination: {
                el: '.home-page .offer .swiper-pagination',
                type: 'bullets',
                clickable: true
            },
        });

        $('.home-page .offer .swiper-container').hover(
            function() {
                offerSwiper.autoplay.stop()
            },
            function() {
                offerSwiper.autoplay.start()
            }
        );
    }

    networkSwiper = new Swiper('.home-page .network .swiper-container', {
        slidesPerView: 1,
        loop: true,
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.home-page .network .swiper-button-next',
            prevEl: '.home-page .network .swiper-button-prev',
        },
        pagination: {
            el: '.home-page .network .swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    });

    $('.home-page .network .swiper-container').hover(
        function() {
            networkSwiper.autoplay.stop()
        },
        function() {
            networkSwiper.autoplay.start()
        }
    );


    // blue arrows
    $('.blue_arrows').click(function () {
        $('html, body').animate({
            scrollTop: 860
        }, 2000);
        return false;
    });

    var blueArrowsInterval = setInterval(function () {
        if ($('.blue_arrows').css('display') === 'none') {
            clearInterval(blueArrowsInterval)
        }

        $('.blue_arrows div').each(function () {
            if (this.className === 'ar1') {
                this.className = 'ar3';
                return;
            }
            if (this.className === 'ar2') {
                this.className = 'ar1';
            }
            if (this.className === 'ar3') {
                this.className = 'ar2';
            }
        });
    }, 200);
});